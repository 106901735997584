<template>
  <div>
    <b-container>

      <b-row>

        <b-col class="col-12 mt-3">
          <label>Reseller</label>
          <b-form-select v-model="sub.reseller" :options="resellers" @change="fetchResellerStockSubs"/>
        </b-col>

        <b-col class="col-12 mt-3">
          <label>Subscription Type</label>
          <b-form-select v-model="sub.type"  name="sub.type" :options="resellerSubStock" @change="updateStockMinMax"  v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('sub.type') }"   
          />
        </b-col>

        <b-col class="col-12 mt-3">
          <b-form-group label="Number of subscriptions to remove">
            <b-input-group>
              <b-form-input
                v-model="sub.number"
                name="number"
                class="darkinput"
                @input="convertToInt"
                type="number"
                :min="stockMin"
                :max="stockMax"
                v-validate="{ required: true, numeric: true, min_value: stockMin, max_value: stockMax }"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-warning" @click="setAllStock">All Stock</b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="errors.has('number')" class="invalid-feedback">{{ errors.first('number') }}</div>
          </b-form-group>
        </b-col>


        <b-col class="col-12 mt-3">
          <label>Reason for removal</label>
          <b-form-input type="search" placeholder="" autocomplete="off" v-model="sub.removalReason" name="sub.removalReason"
            v-validate="{ required: true }" :class="{ 'input': true, 'is-danger': errors.has('sub.removalReason') }"
            class="darkinput" />
        </b-col>

      </b-row>


      <b-row class="mt-5 pb-5">
        <b-col class="col-12">
          <b-button variant="outline-success" block @click="removeStock()">REMOVE FROM RESELLER STOCK</b-button>
        </b-col>
      </b-row>

    </b-container>


  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['reseller','resellerSubs'],
  data() {
    return {
      token: '',
      sub: {
        number:1,
      },
      resellerSubStock: [],
      resellers: [],
      updateStockLock: false,
      stockMin:0,
      stockMax:0,
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.sub.reseller = this.reseller
    this.sub.user = this.$store.getters.user.id
    this.sub.type = 0
    this.fetchResellers()
    this.fetchResellerStockSubs();
  },

  methods: {
    updateStockMinMax(subType){
      this.sub.number = 1;
      const match = this.resellerSubStock.find(item => item.value === subType);
      const count = match ? match.count : 1; 
      this.stockMin = 1;
      this.stockMax = count;
    },

    setAllStock() {
      this.sub.number = this.stockMax;
    },

    fetchResellerStockSubs() {

        axios.get('/api/casterntripsubscriptionsstocklist', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.$store.getters.user.token,
            reseller: this.sub.reseller,
            admin: 1
          }
        })
          .then((response) => {
            this.resellerSubStock.push({ text: 'Select', value: 0 })
            this.resellerSubStock.push(...response.data)
            console.log('Fetch Reseller Stock Subs', response.data)
          })
          .catch(err => {
            this.$swal({
              title: "System Error",
              text: err,
              icon: "error",
              timer: 3000,
              showCloseButton: false,
              showConfirmButton: false

            })
        });
    },


    fetchResellers() {

      axios.get('/api/casterbusinesses', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token
        }
      })
        .then((response) => {
          this.resellers.push({ text: 'Select', value: 0 })
          this.resellers.push(...response.data.CasterBusiness)
        })
        .catch(err => {
          this.$swal({
            title: "System Error",
            text: err,
            icon: "error",
            timer: 3000,
            showCloseButton: false,
            showConfirmButton: false

          })
        });
    },

    removeStock() {
      if (this.updateStockLock) return;
      this.updateStockLock = true;

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.updateStockLock = false;

        } else {

          axios({
            //remove Stock Sub
            method: 'post',
            headers: { "Authorization": "Bearer " + this.token },
            url: '/api/removeResellerSubStock',
            reseller: this.sub.reseller,
            data: {
              userid: this.$store.getters.user.id,
              token: this.$store.getters.user.token,
              subdata: this.sub
            }
          })
            .then((response) => {

              if (response.data.error) {
                this.$swal({
                  title: "Update Error",
                  text: response.data.error,
                  icon: "error",
                  showCloseButton: true,
                  showConfirmButton: false
                });
              } else {
                this.$swal({
                  title: "",
                  text: 'Subscriptions Removed',
                  icon: "success",
                  showCloseButton: true,
                  showConfirmButton: false,
                  timer: 3000
                })
                  .then(() => {
                    this.$emit('updatemodal')
                  });
              }


            }).catch(err => {
              this.$swal({
                title: "Update Error",
                text: err,
                icon: "error",
                showCloseButton: true,
                showConfirmButton: false
              })
            }).finally(() => { this.updateStockLock = false; });
        }
      })
    },

    closemodal() {
      this.$emit('closemodal')
    },

    convertToInt() {
      try {
        if (this.sub.number === "") return;

        let intNumber = parseInt(this.sub.number);

        if (isNaN(intNumber)) {
          alert("number of subs is not a number")
          return;
        }
        this.sub.number = intNumber;
      } catch (err) {
        console.error(err)
      }
    }

  }

};

</script>
