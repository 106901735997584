<template>
<div class="loginpage">

<b-container>

    <b-row class="vh-100" align-v="center">
      <b-col class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

          <b-card v-if="!sent"
            title="Reset Password"
            class="mb-2 loginbox"
            >
          
            <form autocomplete="off" @submit.prevent="requestResetPassword" method="post" @input="has_error = false">
              <div class="form-group">
                  <label for="email">E-mail</label>
                  <input type="email" id="email" class="form-control" placeholder="user@example.com" v-model="email" required>
              </div>

              <span class="invalid-feedback" v-if="has_error">
                    <strong>Email address not found</strong>
             </span>

              <b-button variant="success" type="submit">Send Reset Link</b-button>
            </form>

        </b-card>

        <b-card v-if="sent"
            title="Reset link sent" 
            class="mb-2 loginbox"
            >
            <b-card-text >
            Your password reset email has been sent to {{ email }} 
            </b-card-text>

           
        </b-card>

      </b-col>
    </b-row>

<div class="fluid-container footer">
    <p class="text-center">Nick Abbey Digital Agriculture Ltd &copy; 2022</p>
</div>

</b-container>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        email: '',
        has_error: false,
        sent:false
      }
    },
    methods: {
        requestResetPassword() {

           this.has_error = false             

            axios({
                method: 'post',
                url: '/api/resetpassword',
                data: {email:this.email}
                })
                .then((response) => {
                    if(response.data.messages == 'Password reset email sent.'){
                        this.sent = true
                    }
                    if(response.data.messages == 'Email could not be sent to this email address.'){
                        this.has_error = true
                    }
                })
                .catch(err => alert(err))
        }
    }
}
</script>