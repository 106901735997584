<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="col-12 mt-3">
          <label>Dealer</label>
          <h5>{{ dealer.dealer }}</h5>
        </b-col>

        <b-col class="col-12 mt-3">
          <label>Subscription Type</label>
          <b-form-select
            v-model="sub.id"
            :options="subs"
            @change="updateStockMinMax($event)"
            name="sub.type"
            v-validate="{ min_value: 1 }"
            :class="{ 'b-form-select': true, 'is-danger': errors.has('sub.type') }"
          />
        </b-col>

        <b-col class="col-12 mt-3">
          <b-form-group label="Number of subscriptions to remove">
            <b-input-group>
              <b-form-input
                v-model="sub.number"
                type="number"
                class="darkinput"
                :min="1"
                :max="stockMax"
                name="sub.number"
                v-validate="{ required: true, numeric: true, min_value: 1, max_value: stockMax }"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-warning" @click="setAllStock">All Stock</b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="errors.has('sub.number')" class="invalid-feedback">
              {{ errors.first('sub.number') }}
            </div>
          </b-form-group>
        </b-col>

        <b-col class="col-12 mt-3">
          <label>Reason for removal</label>
          <b-form-input
            v-model="sub.removalReason"
            type="text"
            name="sub.removalReason"
            v-validate="{ required: true }"
            :class="['darkinput', { 'is-danger': errors.has('sub.removalReason') }]"
          />
        </b-col>
      </b-row>

      <b-row class="mt-5 pb-5">
        <b-col class="col-12">
          <b-button variant="outline-danger" block @click="removeStock">REMOVE DEALER STOCK</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['reseller', 'dealer'],
  data() {
    return {
      token: '',
      sub: {
        id: 0,
        number: 1,
        removalReason: '',
      },
      subs: [...this.dealer.subs],
      stockMax: 0,
      updateStockLock: false,
    };
  },
  created() {
    this.token = this.$store.getters.token;
    this.sub.reseller = this.reseller;
    this.sub.dealer = this.dealer.id;
    this.sub.user = this.$store.getters.user.id;
    this.subs.unshift({text: 'Select', value: 0})
  },
  methods: {

    updateStockMinMax(subType){
      this.sub.number = 1;
      const match = this.subs.find(item => item.value === subType);
      const count = match ? match.count : 1; 
      this.stockMin = 1;
      this.stockMax = count;
    },

    setAllStock() {
      this.sub.number = this.stockMax;
    },

    removeStock() {
      if (this.updateStockLock) return;
      this.updateStockLock = true;

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.updateStockLock = false;
          return;
        }

        axios.post('/api/removeDealerSubStock', {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          subdata: this.sub,
        }, {
          headers: { Authorization: 'Bearer ' + this.token },
        })
        .then(response => {
          if (response.data.error) {
            this.$swal({ title: 'Update Error', text: response.data.error, icon: 'error', showCloseButton: true });
          } else {
            this.$swal({ title: '', text: 'Subscriptions Removed', icon: 'success', timer: 3000 });
            this.$emit('updatemodal');
          }
        })
        .catch(err => {
          this.$swal({ title: 'Update Error', text: err, icon: 'error', showCloseButton: true });
        })
        .finally(() => {
          this.updateStockLock = false;
        });
      });
    },
  },
};
</script>
