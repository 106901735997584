<template>
  <div>

    <b-container v-if="display">

      <b-row>
        <b-col class="col-12 mt-3">
          <h5>{{ rover.text }}</h5>
        </b-col>

        <b-col class="col-12 col-sm-6">
          <b-row>
            <b-col class="col-12">{{ rover.machine[0].text }}</b-col>
            <b-col class="col-12 mt-1">{{ company.title }}</b-col>
            <b-col class="col-12">{{ company.address1 }}</b-col>
            <b-col class="col-12" v-if="company.address2">{{ company.address2 }}</b-col>
            <b-col class="col-12" v-if="company.address3">{{ company.address3 }}</b-col>
            <b-col class="col-12">{{ company.towncity }}</b-col>
            <b-col class="col-12">{{ company.postcode }}</b-col>
          </b-row>
        </b-col>

        <b-col class="col-12 col-sm-6">

          <b-row v-if="rover.subscriptiondetail.length > 0">
            <b-col class="col-12">
              <h6>{{ sub.status }} Subscription</h6>
            </b-col>
            <b-col class="col-12">{{ subtitle }}</b-col>
            <b-col class="col-12">{{ sub.startdate }} - {{ sub.enddate }}</b-col>
          </b-row>

          <b-row v-else>
            <b-col class="col-12">
              <h6>No Subscription</h6>
            </b-col>
          </b-row>

          <b-row class="mt-3" v-if="rover.simm.id > 0">
            <b-col class="col-12">
              <h6>SIM</h6>
            </b-col>
            <b-col class="col-12">{{ rover.simm.iccid }}</b-col>
          </b-row>

          <b-row class="mt-3" v-else>
            <b-col class="col-12">
              <h6>No SIM</h6>
            </b-col>
          </b-row>

        </b-col>

      </b-row>

      <b-row class="mt-5 mb-3">
        <b-col class="col-12">
          <b-button variant="outline-danger" block @click="deleteRover()">DELETE</b-button>
        </b-col>
      </b-row>

    </b-container>

    <b-container v-else>

      <b-row>
        <b-col class="col-12 mt-3">
          <h5>{{ rover?.text }} Deleted</h5>
        </b-col>
      </b-row>

    </b-container>

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['id'],
  data() {
    return {
      token: '',
      rover: {},
      sub: {},
      subtitle: '',
      company: {},
      user: {},
      display: false
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.user = this.$store.getters.user
    this.fetchRover()
  },

  methods: {

    fetchRover() {

      axios.get('/api/companyroverstest', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.id
        }
      })
        .then((response) => {
          this.rover = response.data.rovers[0]
          console.log(response.data)
          if (this.rover.subscriptiondetail.length != 0) {
            this.sub = this.rover.subscriptiondetail[0]
            this.sub.startdate = moment.unix(this.sub.startdate).format('DD-MM-YYYY')
            this.sub.enddate = moment.unix(this.sub.enddate).format('DD-MM-YYYY')
            this.subtitle = this.rover.subscriptiondetail[0].subscription[0].text
          }
          this.company = this.rover.companydetail
          this.display = true
        })
        .catch(err => {
          this.$swal({
            title: "System Error",
            text: err,
            icon: "error",
            timer: 3000,
            showCloseButton: false,
            showConfirmButton: false

          })
        });
    },

    deleteRover() {

      let text = "This will remove " + this.rover.text + " and associated sim / subscription.<br/><br/>Are you sure?"

      this.$swal({
        title: "Delete Rover",
        html: text,
        icon: "warning",
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
      })
        .then((result) => {

          if (result.isConfirmed) {

            axios({
              method: 'post',
              headers: { "Authorization": "Bearer " + this.token },
              url: '/api/roverarchive',
              data: {
                userid: this.$store.getters.user.id,
                token: this.$store.getters.user.token,
                id: this.id
              }
            })
              .then(

                this.userEvent()

              )
              .catch(err => {
                this.$swal({
                  title: "Delete Error",
                  text: err,
                  icon: "error",
                  showCloseButton: true,
                  showConfirmButton: false
                })
              });

          }
        })

    },

    userEvent() {   //  Set log event - ROVER ARCHIVE

      axios({
        method: 'post',
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/userevent',
        data: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          user: this.user.id, type: 16, text: this.rover.text
        } // Archive User event
      })
        .then((response) => {

          if (response.data.error) {
            this.$swal({
              title: "Update Error",
              text: response.data.error,
              icon: "error",
              showCloseButton: true,
              showConfirmButton: false
            })

          } else this.$emit('updatemodal')


        }).catch(err => {
          this.$swal({
            title: "Update Error",
            text: err,
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false
          })
        }).finally( () => {
          this.display = false;
          setTimeout(this.closemodal, 3000);
        }) 
    },


    closemodal() {
      this.$emit('closemodal')
    },

  }

};

</script>