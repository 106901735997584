<template>
<div class="imageBorder" style="color:rgba(255,255,255,0.7)">
    
    <b-container v-if="!geoupdate">
       
        <b-row class="mt-2" v-if="errormessage == ''">
            <b-col class="col-12 col-md-6">
                <b-row>
                <b-col class="col-5">Country</b-col>
                <b-col class="col-7">{{ basedata.countryName}}</b-col>
                <b-col class="col-5">Operator</b-col>
                <b-col class="col-7">{{ basedata.operatorName}}</b-col>
                <b-col class="col-5">Radio Access Type</b-col>
                <b-col class="col-7">{{ basedata.cellIdRatType}}</b-col>
                <b-col class="col-5">Cell ID</b-col>
                <b-col class="col-7">{{ basedata.cellId}}</b-col>
                
                </b-row>
           </b-col>  
           <b-col class="col-12 col-md-6">
                <b-row>
                <b-col class="col-5">MCC</b-col>    
                <b-col class="col-7">{{ basedata.mcc}}</b-col>
                <b-col class="col-5">MNC</b-col>
                <b-col class="col-7">{{ basedata.mnc}}</b-col>
                <b-col class="col-5">LAC</b-col>
                <b-col class="col-7">{{ basedata.lac }}</b-col>
                <b-col class="col-5">Location Info Age</b-col>
                <b-col class="col-7">{{ basedata.locationInfoAge}}</b-col>
                </b-row>
           </b-col>     
        </b-row>

        <b-row class="mt-2" v-else>
            <b-col class="col-12 text-center">
                <h6>{{  errormessage  }}</h6>
            </b-col>
        </b-row>        

        <b-row class="mt-3">
            <b-col class="col-12 col-md-6">
                <b-btn variant="outline-success" block size="md" @click.stop="fetchGeo()">Check GEOLOCATION</b-btn>
            </b-col>
            <b-col class="col-12 col-md-6">
                <b-btn variant="outline-danger" block size="md" @click.stop="cancelGeoConfirm()">Cancel GEOLOCATION</b-btn>
            </b-col>
        </b-row>

    </b-container>
    
    <b-container v-else>
        <div class="text-center text-warning my-2 p-3">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ geoupdatemessage }}</strong>
        </div>
    </b-container>
  
<b-modal ref="geo-confirm" size="lg" hide-header hide-footer centered content-class="darkmodal2">
    <div v-if="!geoupdate && !geocancelled">
        <b-btn variant="danger" block disabled><h5>Warning!</h5></b-btn>
        <b-row class="p-3">
            <b-col class="col-12 mb-3">  
                <h6>Before you proceed please communicate with the operator/end user to ensure they are aware you have activated this function, as the rover NTRIP modem may need to be power cycled to re-establish a cellular connection.</h6>
            </b-col>
            
            <b-col class="col-12 col-md-6 mt-3">
                <b-btn variant="outline-danger" block size="md" @click="cancelGeo()">Yes I understand the risk</b-btn>
            </b-col> 
            
            <b-col class="col-12 col-md-6 mt-3">
                <b-btn variant="outline-success" block size="md" @click="closeGeo()">Cancel</b-btn>
            </b-col> 
        </b-row>
    </div>  

    <div v-if="geoupdate">
        <div class="text-center text-warning my-2 p-3">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ geoupdatemessage }}</strong>
        </div>
    </div>

    <div v-if="geocancelled">
        <div class="text-center p-3">
            <h5>{{ geoupdatemessage }}</h5>
        </div>
    </div>


</b-modal>


</div>
</template>
    
    
    <script>
       import axios from 'axios'
        export default {
            props:['reseller','endpoint','iccid'],
            name:'CasterSimGeo',
            components:{
               
            },
            data(){
            return {
                    token:'',
                    basedata:{
                        countryName:'-',
                        operatorName:'-',
                        cellIdRatType:'-',
                        cellId:'-',
                        mcc:'-',
                        mnc:'-',
                        lac:'-',
                        locationInfoAge:'-'
                    },
                    errormessage:'',
                    geoupdate:false,
                    geoupdatemessage:'Getting geoposition data',
                    geocancelled:false
                    };
                    
                    },
    
            mounted(){
                this.token = this.$store.getters.token
                console.log(this.iccid,this.endpoint)
            },
   
            methods: {
    
                fetchGeo(){
                    this.geoupdate = true
                    axios.get('/api/casterbicsgetgeoposition',{
                        headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            endpoint:this.endpoint,
                            reseller:this.reseller
                            }
                    })
                    .then((response) => {
                         this.geoupdate = false
                         if(response.data.code == 999){
                            this.errorresponse = response.data
                          } else {
                                if(response.data.error == true){
                                    this.errormessage = response.data.messages.message
                                } else this.basedata = response.data.location
                           }       
    
                        });
                },

                cancelGeoConfirm(){
                        this.$refs['geo-confirm'].show()
                }, 
                
                closeGeo(){
                        this.$refs['geo-confirm'].hide()
                },  

                cancelGeo(){
                    this.geoupdatemessage = 'Cancelling geoposition'
                    this.geoupdate = true
                    
                    axios({
                    method: 'post',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casterbicscancelgeoposition',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    endpoint:this.endpoint,
                                    iccid:this.iccid,
                                    reseller:this.reseller
                                    }
                                })
                                .then((response) => {
                                    console.log(response.data)
                                    this.geoupdate = false
                                    this.geocancelled = true
                                    this.geoupdatemessage = response.data.messages
                                 })
                }
                
            }	   
        
        };
</script> 