<template>
<div class="imageBorder" style="font-size:0.8em">

        
        <b-row class="mt-4" >
            <b-col class="col-6">ROVER STATUS</b-col>
            <b-col class="col-6" v-if="rover.connected == 0">
               <div class="rtmarker" :style="{ backgroundColor:'#d44f3b',marginRight:'10px' }" /> 
                Not Connected
                </b-col>
            <b-col class="col-6" v-else>
                <div class="rtmarker" :style="{ backgroundColor:'#3CD2A5',marginRight:'10px' }" /> 
                Connected
                </b-col>
        </b-row>

        <b-row class="mt-1">    
             <b-col class="col-6">DIFF STATUS</b-col>
            <b-col class="col-6" v-if="rover.connected  == 1">
                <div class="rtmarker" :style="{ backgroundColor:rover.color,marginRight:'10px' }" />
                {{ rover.rtk_message }}
            </b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">DISTANCE</b-col>
            <b-col class="col-6" v-if="rover.connected == 1">
              <span v-if="rover.distance < 1">
                {{ (rover.distance * 1000) }} m
              </span>
              <span v-else>
                {{ Number(rover.distance).toFixed(1)}} km
              </span>
            </b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">DATA RECEIVED</b-col>
            <b-col class="col-6" v-if="rover.connected  == 1">
                <div class="rtmarker" :style="{ marginRight:'10px',backgroundColor:dataind1 }" /> {{ bytesrcvd }} kb</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">DATA SENT</b-col>
            <b-col class="col-6" v-if="rover.connected  == 1">
                <div  class="rtmarker" :style="{ marginRight:'10px',backgroundColor:dataind2  }" /> {{ bytessent }} kb</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1 mb-3">
            <b-col class="col-6">CONN DURATION</b-col>
            <b-col class="col-6" v-if="rover.connected  == 1">{{ duration }}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>


</div>
</template>

<style>
.data{background-color:'red' !important;}
.nodata{background-color:'yellow' !important;}
</style>

<script>
  
	export default {
        props:['rover'],
        data(){
            return {
                duration:0,
                bytessent:999,
                bytesrcvd:999,
                dataind1:'#708675',
                dataind2:'#708675',
                connecttimer:'',
                start:0,
                };
                
                },

		mounted(){
            this.setRover()
           
        },

        beforeDestroy () {
            clearInterval(this.connecttimer)
        },

        watch: {
              rover: function () {
                    this.setRover()
              },
          },

     
		methods: {


           setRover(){

            if(this.start == 0){this.start = this.rover.connection_time}
          
            let sentdata = Math.round(this.rover.bytessent/1024)
            if(sentdata != this.bytessent){
                this.dataind2 = '#53C16B'
                setTimeout(this.flashData2,500)
                this.bytessent = sentdata
            }

            if(this.rover.bytesrcvd != this.bytesrcvd){
                this.dataind1 = '#53C16B'
                setTimeout(this.flashData1,500)
                this.bytesrcvd = this.rover.bytesrcvd
            }

            
            if(!this.connecttimer){
                this.connecttimer = setInterval(this.connectTime, 1000) 
                }

           }, 

           flashData1(){
               this.dataind1 = '#708675';
           },
           
           flashData2(){
               this.dataind2 = '#708675';
           }, 

        
           connectClock(){  
                // if(!this.connecttimer){
                        this.connecttimer = setInterval(this.connectTime, 1000) 
                //        }    
                
            },

            connectTime(){
                    this.start = this.start + 1
                    let days = parseInt(this.start/86400)
                    let hours = Math.trunc(parseInt(this.start - (days * 86400))/3600) 
                    let minutes = Math.trunc(parseInt((this.start - (days * 86400) -(hours * 3600))/60)) 
                    let secs = Math.trunc((this.start - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                    this.duration = days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
            },

        }	   
	
	};

</script> 