<template>
<div class="imageBorder">

    <b-row>

        <b-col class="col-12 col-md-3 mt-3">
            <label>RTK Fix Status:</label>
            <b-form-select
                        id="status-sel"
                        v-model="statussel"
                        :options="statustypes"
                        class="darkinput2"
                        size="sm"
                        @change="setStatus"
                        />
        </b-col>

        <b-col class="col-12 col-md-4 offset-md-1 mt-5">
            <CasterSearchSuggestions 
            placeholder="search rovers"
            :clearsearch="clearsearch"
            suggestionType = "1"
            :suggestionFetchResponse="fetchResponse" 
            v-on:textsearch=updateSearch 
            v-on:searchtext2=updateSearch
            v-on:clearsearch=clearFilter
            />
        </b-col>
</b-row>

    <b-row>       
    <b-col class="col-12">
        <CasterRoversLoggedTable 
            :stext="searchtext"
            :statussel="statussel"
            :clearsearch="clearsearch" 
            v-on:clearstext="clearSearch"
            v-on:fetchresponse=setFetchResponse
            v-on:rtkstatus=setCounts  
            />
    </b-col>
    </b-row>

</div>
</template>

<script>
    import CasterSearchSuggestions from './CasterSearchSuggestions'
    import CasterRoversLoggedTable  from './CasterRoversLoggedTable'
    
    export default {
        name:'CasterRoversListLoggedCard',
        components:{
            CasterSearchSuggestions,
            CasterRoversLoggedTable
        }, 
        data(){
        return {
                searchtext:'',
                fetchResponse:'',
                clearsearch:true,
                statussel:10,
                statustypes:[ // this gets overwritten by the table emit calling setCounts
                    { value:10, text:'All'},
                    { value:4, text:'Fix'},
                    { value:5, text:'Float'},
                    { value:2, text:'DGPS'},
                    { value:1, text:'GPS'},
                    { value:6, text:'EST'},
                    { value:0, text:'Connected'},//purple '-'
                ],
                };
                
                },
       
        methods: {

            setStatus(event){
                this.storeTableFilter(event,this.filtertype)
            },

            storeTableFilter(value,type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type != type) // clear previous value
                searchvalues.push({type:type,value:value})
                this.$store.commit('setsearchvalues2',searchvalues)
          },

            getTableFilter(type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type == type)

                if(searchvalues.length > 0){
                    return searchvalues[0].value
                } else return 0
            },

            updateSearch(text){
                this.searchtext = text
            },

            setFetchResponse(text){
                this.fetchResponse = text
            },

            clearSearch(){
                this.searchtext = ''
                this.clearsearch = !this.clearsearch
                this.setDefaults()
            },

            clearFilter(){
                this.searchtext = ''
            },

            setCounts(counts){
                this.statustypes = counts
            }
		}	   
	};
</script> 