<template>
<div>

<b-container class="mainpagecont" v-if="notauth">
    <b-row>
        <b-col class="col-12 col-sm-8 offset-sm-2 p-5">
            <b-button variant="danger" block disabled>UNAUTHORISED TO VIEW THIS RESOURCE</b-button>
        </b-col>
    </b-row>
</b-container>     

<b-container class="mainpagecont" v-if="display">
  
<b-row>
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <h3>Manage Rover</h3>
    </b-col>
    <b-col class="col-12 col-md-6 mt-1 p-2 text-right">
        <b-button size="sm" variant="outline-success" style="min-width:150px;margin-left:5px" @click="realTime(rover.value)"  v-if="rover.rtk_status > 0 && rover.lastsession !== null">
            <b-icon-clock class="bicon" /> VIEW LIVE
        </b-button>
        <b-button size="sm" variant="outline-success" style="min-width:150px;margin-left:5px" @click="history(rover.value)" v-if="rover.lastsession !== null">
            <b-icon-calendar class="bicon" /> HISTORY
        </b-button> 
    </b-col>  
</b-row> 

<b-row>

    <b-col class="col-12 col-md-6 mt-0 p-2" style="font-size:0.7em;color:rgba(255,255,255,0.7)">
        <h5>{{ rover.text }}</h5>
    </b-col>

    <b-col class="col-12 col-md-6 text-md-right mt-0 p-2" v-if="rover.connected == 1">
        <div  class="rtkstatus2" :style="{backgroundColor:rover.color}">{{ rover.statustext }}</div>
    </b-col>
</b-row>        


<b-row>

    <b-col class="col-12 mt-1 p-2"><h5>Details</h5></b-col>
    
    <b-col class="col-12 mt-1 p-2">

        <div class="imageBorder">

            <b-row class="mt-1" >
                <b-col class="col-4">Title</b-col>
                <b-col class="col-8">{{ rover.text }}</b-col>
                <b-col class="col-4">Machinery</b-col>
                <b-col class="col-8">{{ machine.make_title }} {{ machine.model_title }}</b-col>
                <b-col class="col-4">Reg</b-col>
                <b-col class="col-8">{{ machine.regnum }}</b-col>
                <b-col class="col-4">Company</b-col>
                <b-col class="col-8"><span v-if="rover.companydetail">{{ rover.companydetail.title }}</span></b-col>
                <b-col class="col-4">Dealer</b-col>
                <b-col class="col-8"><span v-if="rover.dealerdetail">{{ rover.dealerdetail.title }}</span></b-col>
                <b-col class="col-4">Needs GGA</b-col>
                <b-col class="col-8">{{ rover.needs_gga }}</b-col>
                <b-col class="col-4">Disconnect on change Base</b-col>
                <b-col class="col-8">{{ rover.disconnect_onchangebase }}</b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col class="col-12 col-lg-6" v-if="adminedit || reselleredit">
                <b-button @click.stop="roverAdd()" variant="outline-success" block >Edit rover</b-button>
                </b-col>
                <b-col class="col-12 col-lg-6" v-else>
                <b-button @click.stop="editMachine()" variant="outline-success" block>Edit machinery</b-button>
                </b-col>
            </b-row>
       
        </div>

    </b-col>

    <b-col class="col-8 mt-1 p-2" ><h5>Notes</h5></b-col>
    <b-col class="col-4 mt-1 p-2 text-right" >
        <b-button variant="outline-success" size="sm" style="margin-right:5px" @click.stop="addNote()"><b-icon-plus-circle /> Add note</b-button>
    </b-col>
        
    <b-col class="col-12 mt-1 p-2" v-if="rover.notes.length > 0">

        <div class="imageBorder">

            <b-row class="mt-1 mb-1" v-for="note in rover.notes" :key="note.id">

                <b-col class="col-12 col-sm-8">

                <div v-html="note.description" style="white-space: pre"></div>
                    <span style="font-size:0.9em;margin-right:10px">{{  note.name }}</span>
                    <span style="font-size:0.9em">{{  note.datetime }}</span>
                    
                </b-col>
                <b-col class="col-12 col-sm-4 text-right">
                    <b-button variant="outline-success" size="sm" style="margin-right:5px" @click.stop="showNote(note)"><b-icon-pencil /></b-button>
                    <b-button variant="outline-danger" size="sm" @click.stop="delNote(note)"><b-icon-x /></b-button>
                </b-col>

            </b-row>
        </div>
    </b-col>


    <b-col class="col-12 mt-1 p-2"><h5>Subscription</h5></b-col>
        
    <b-col class="col-12 mt-1 p-2" >

        <div class="imageBorder" v-if="roversubslength > 0">

            <b-row class="mt-1" >

                <b-col class="col-12 col-sm-4">
                    <h5>Current Details</h5>
                </b-col>
                 <b-col class="col-12 col-sm-4 mt-1 mb-3">
                    <div class="rtmarkerexpiry" :style="{ backgroundColor:sub.expirycolor}" v-if="sub.status == 'Active'">
                {{ sub.status}} {{ sub.expirydays }} day<span v-if="sub.expirydays !== 1">s</span> remaining
                    </div>
                    <div class="rtmarkerexpiry" :style="{ backgroundColor:sub.expirycolor}" v-else>
                {{ sub.status}} 
                    </div>
                </b-col>
              </b-row>

              <b-row class="mt-1" >

                <b-col class="col-4">Type</b-col>
                <b-col class="col-8">{{ sub.subscription[0].text }}</b-col>
                <b-col class="col-4">Activation</b-col>
                <b-col class="col-8">{{ formatDate(sub.startdate) }}</b-col>
                <b-col class="col-4">Expiration</b-col>
                <b-col class="col-8">{{ formatDate(sub.enddate) }}</b-col>
                <b-col class="col-4">NTRIP User ID</b-col>
                <b-col class="col-8">{{ rover.username }}</b-col>
                <b-col class="col-4">NTRIP Password</b-col>
                <b-col class="col-8">{{ rover.password }}</b-col>
                <b-col class="col-4">First Activation</b-col>
                <b-col class="col-8">{{ formatDate(sub.firstactivation) }}</b-col>
          
            </b-row>

            <b-row class="mt-3" v-if="renewsub != 0">
                <b-col class="col-12 col-sm-4">
                    <h5>Renewal Details</h5>
                </b-col>
                 <b-col class="col-12 col-sm-4 mt-1 mb-3">
                    <div class="rtmarkerexpiry" style="background-color:#e8be32">{{ renewsub.statustext }}</div>
                </b-col>
                 <b-col class="col-sm-4"></b-col>
                <b-col class="col-4">Type</b-col>
                <b-col class="col-8">{{ renewsub.subscription[0].title }}</b-col>
                <b-col class="col-4">Activation</b-col>
                <b-col class="col-8">{{ renewsub.startdate2 }}</b-col>
                <b-col class="col-4">Expiration</b-col>
                <b-col class="col-8">{{ renewsub.enddate2 }}</b-col>
                <b-col class="col-4">Purchase Order</b-col>
                <b-col class="col-8">{{ renewsub.purchase_order }}</b-col>
                <b-col class="col-4">Created</b-col>
                <b-col class="col-8">{{ renewsub.created }}</b-col>
            </b-row> 

            <b-row>
                <b-col class="col-12 col-sm-6 mt-3">
                    <b-button variant="outline-success" block @click.stop="renewSub()" v-if="renewsub == 0" >Renew subscription</b-button>
                    <b-button variant="outline-warning" block @click.stop="cancelRenewSub()" v-else>Cancel scheduled renewal</b-button>

                </b-col>
                <b-col class="col-12 col-sm-6 mt-3" v-if="sub.expirydays > 0">    
                    <b-button variant="outline-warning" @click.stop="cancelSub()" block>Cancel subscription</b-button>
                </b-col>
            </b-row>       
        
        </div>

        <div class="imageBorder" v-else >

        <b-col class="col-12 p-4 text-center">
            <b-button variant="outline-success" @click.stop="addSub()" block>Add subscription</b-button>
        </b-col>

        </div>

        </b-col>


        <b-col class="col-12 mt-3 p-2"><h5>NTRIP Settings</h5></b-col>
        <b-col class="col-12 mt-1 p-2">
            <CasterRoverNTRIPDetails
                :rover = "rover" 
                :subid = "rover.subscription"
                :email = "rover.email" 
            />
        </b-col>
</b-row>

<b-row v-if="adminedit">
        <b-col class="col-12 mt-3 p-2"><h5>Casta Settings</h5></b-col>
        <b-col class="col-12 mt-1 p-2">
            <CasterRoverNTRIPCastaDetails
                :key="update" 
                :id="rover.value"
                :rover="rover"
                v-on:updatemodal=updateModal
            />
        </b-col>
</b-row>

<b-row>

    <div v-if="roversubslength > 0" style="width:100%">
      
        <b-col class="col-12 mt-3 p-2"><h5>SIM Card</h5></b-col>

        <b-col class="col-12 mt-1 p-2">

        <div class="imageBorder">

            <b-row class="mt-1" v-if="rover.simm.length != 0">
                <b-col class="col-4">Type</b-col>
                <b-col class="col-8">{{ rover.simm[0].package }}</b-col>
                <b-col class="col-4" v-if="simsupplier == 1">APN</b-col>
                <b-col class="col-8" v-if="simsupplier == 1">{{ rover.simm[0].apn }}</b-col>
                <b-col class="col-4" v-if="simsupplier == 1">Connection ID</b-col>
                <b-col class="col-8" v-if="simsupplier == 1">{{ rover.simm[0].connection_id }}</b-col>
                <b-col class="col-4" v-if="simsupplier == 2">Endpoint</b-col>
                <b-col class="col-8" v-if="simsupplier == 2">{{ rover.simm[0].endpoint }}</b-col>
                <b-col class="col-4" v-if="simsupplier == 2">Endpoint ID</b-col>
                <b-col class="col-8" v-if="simsupplier == 2">{{ rover.simm[0].endpoint_id }}</b-col>
                <b-col class="col-4">ICCID</b-col>
                <b-col class="col-8">{{ rover.simm[0].text }}</b-col>
                <b-col class="col-4">Status</b-col>
                <b-col class="col-8">{{ rover.simm[0].status }}</b-col>
                <b-col class="col-4">Online</b-col>
                <b-col class="col-8"><span v-if="rover.simm[0].online==0">no</span><span v-else>yes</span></b-col>
                <b-col class="col-4">Consumed Data</b-col>
                <b-col class="col-8">{{ consumed }} MB</b-col>
                <b-col class="col-4">Network</b-col>
                <b-col class="col-8">{{ rover.simm[0].service }}</b-col>

                
            </b-row>

            <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center">
                    <h6>Not Supplied</h6>
                </b-col>
            </b-row> 
            
        </div>

        </b-col>  

    </div>

</b-row>

<b-row class="mt-1" v-if="simsupplier == 2 && rover.simm.length > 0">
    <b-col class="col-12 mt-3 p-2"><h5>GEOLOCATION</h5></b-col>
    <b-col class="col-12 mt-1 p-2">
    <CasterSimGeo 
        :endpoint = "rover.simm[0].endpoint_id" 
        :reseller="rover.reseller"
        :iccid = "rover.simm[0].number"
        />
    </b-col>
</b-row>

<b-row v-if="roversubslength > 0">

        <b-col class="col-12 mt-1 p-2"><h5>Mountpoints</h5></b-col>

        <b-col class="col-12 mt-2 p-2">

        <div class="imageBorder">

             <b-row class="mt-1 p-2" >
                <b-col class="col-4">Available Mountpoints</b-col>
                <b-col class="col-8">RTK_RTCM3</b-col>
            </b-row>

        </div>

        </b-col>
    
        <b-col class="col-12 mt-1 p-2"><h5>Usage</h5></b-col>
        
                  <b-col class="col-12 col-md-6 mt-1 p-2">
                     <CasterRoverConnectionsChartCard :roverid="rover.value" />
                 </b-col>   

                 <b-col class="col-12 col-md-6 mt-1 p-2">
                     <CasterRoverConnectionTimeChartCard :roverid="rover.value" />
                 </b-col>   
                
                 <b-col class="col-12 col-md-6 mt-1 p-2">
                     <CasterRoverLast5Card :id="id" />
                 </b-col>   
                 
                <b-col class="col-12 col-lg-6 mt-1 p-2" v-if="rover.simm.length != 0">
                    <CasterSimmUsageCard :roverid="rover.value" />
                </b-col>  
</b-row>

<b-row class="mt-3">
        <b-col class="col-12 mt-1 p-2" ><h5>Action History</h5></b-col>
        <b-col class="col-12 mt-1 p-2">
            <CasterRoverEventsCard :update="false" :roverid="rover.value" />
        </b-col>  
</b-row>

<b-row class="mb-5">
        <b-col class="col-12 mt-1 p-2" ><h5>Email History</h5></b-col>
        <b-col class="col-12 mt-1 p-2">
            <CasterRoverEmailsCard 
                :resellerid="rover.business"
                :dealerid="rover.dealer"
                :companyid="rover.company"
                
                />
        </b-col>  
</b-row>


<b-modal ref="rover-add" size="lg" hide-header hide-footer centered content-class="darkmodal">
        <CasterRoverAdd
            :roverid = rover.value
            v-on:closemodal="closemodal()"
            v-on:updatemodal="updateModal"
            />
</b-modal> 

<b-modal ref="machine-edit" size="md" hide-header hide-footer centered content-class="darkmodal">
    
            <CasterCompanyMachineEditOld
            :machinedata = "machine"
            :edit="true"
            :company ="rover.company"
            v-on:closemodal="closemodal()"
            v-on:updatemachine="updateModal"
            />

</b-modal>

<b-modal ref="rover-note" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <CasterNote
            :note = notesel
            v-on:closemodal="closemodal()"
            v-on:updatemodal="updateModal"
            />
</b-modal>

<b-modal ref="rover-notedel"  hide-header  hide-footer centered content-class="darkmodal" @hide="handleHide2">
    <b-row class="mt-3">
        <b-col class="col-12 text-center mb-3"><h5>Delete note?</h5></b-col>   
        <b-col class="col-12 col-md-6">    
            <b-button size="sm" block variant="outline-danger" @click.stop="deleteNote(notesel)">Delete</b-button>
        </b-col>
        <b-col class="col-12 col-md-6">
            <b-button size="sm" block variant="outline-info" @click.stop="cancelNote()">Cancel</b-button>
        </b-col>
        
    </b-row>
</b-modal>


<b-modal ref="sub-renew"  :title="modaltitle"  hide-footer centered content-class="darkmodal" @hide="handleHide2">
   <CasterSubscriptionRenew :subid="sub.value" :dealerid="rover.dealer" v-on:update="handleHide2" />
</b-modal>

<b-modal ref="renew-cancel"  :title="modaltitle"  hide-footer centered content-class="darkmodal" @hide="handleHide2">
   <CasterSubscriptionCancelRenew :subid="sub.value" :dealerid="rover.dealer" v-on:update="handleHide2" />
</b-modal>

<b-modal ref="sub-cancel"  :title="modaltitle"  hide-footer centered content-class="darkmodal" @hide="handleHide2">
   <CasterSubscriptionCancel :subid="sub.value" :dealerid="rover.dealer" v-on:update="handleHide2" />
</b-modal>

<b-modal ref="sub-add"  :title="modaltitle"  hide-footer centered content-class="darkmodal" @hide="handleHide2">
   <CasterSubscriptionAdd :rover="rover" v-on:update="handleHide2" />
</b-modal>

<!-- <b-modal ref="edit-rover"  :title="modaltitle" hide-footer centered content-class="darkmodal" @hide="handleHide2">
   <CasterRoverEdit :id="rover.value" v-on:update="updateModal" />
</b-modal> -->


</b-container>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
	
</div> 
</template>


<script>
    import CasterRoverNTRIPDetails from './CasterRoverNTRIPDetails'
    import CasterRoverNTRIPCastaDetails from './CasterRoverNTRIPCastaDetails'
    import CasterRoverEventsCard from './CasterRoverEventsCard'
    import CasterRoverEmailsCard from './CasterRoverEmailsCard'
    import CasterRoverConnectionsChartCard from './CasterRoverConnectionsChartCard'
    import CasterRoverConnectionTimeChartCard from './CasterRoverConnectionTimeChartCard'
    import CasterRoverLast5Card from './CasterRoverLast5Card'
    import CasterSimmUsageCard from './CasterSimmUsageCard'
    import CasterSubscriptionRenew from './CasterSubscriptionRenew'
    import CasterSubscriptionCancelRenew from './CasterSubscriptionCancelRenew'
    import CasterSubscriptionCancel from './CasterSubscriptionCancel'
    import CasterSubscriptionAdd from './CasterSubscriptionAdd'
    //import CasterRoverEdit from './CasterRoverEdit'
    import CasterNote from './CasterNote'
    import CasterRoverAdd from './CasterRoverAdd'
    import CasterCompanyMachineEditOld from './CasterCompanyMachineEditOld'
    import CasterSimGeo from './CasterSimGeo'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
	export default {
    name:'CasterRoverManage',
    components:{
            CasterRoverNTRIPDetails,
            CasterRoverNTRIPCastaDetails,
            CasterRoverEventsCard,
            CasterRoverEmailsCard,
            CasterRoverConnectionsChartCard,
            CasterRoverConnectionTimeChartCard,
            CasterRoverLast5Card,
            CasterSimmUsageCard,
            CasterSubscriptionRenew,
            CasterSubscriptionCancelRenew,
            CasterSubscriptionCancel,
            CasterSubscriptionAdd,
            CasterNote,
            //CasterRoverEdit,
            CasterRoverAdd,
            CasterCompanyMachineEditOld,
            CasterSimGeo,
            ErrorLogging
        },
    data(){
		return {
		
            id:0,
			token:'',
            lastsessiondate:'',
			user:{},
            rover:{},
            sub:{},
            casta:{},
            machine:{
                make_title:'-',
                model_title:'-',
                regnum:'-'
            },
            renewsub:{},
            notesel:{},
            resellersel:0,
            dealersel:0,
            companysel:0,
            simsupplier:0,
            consumed:0,
            modaltitle:'',
            title:'',
            roversubslength:0,
            display:false,
            errorresponse:{},
            text: 'This will get copied!',
            update:0,
            notauth:false,
            settimeout:false,
            adminedit:false,
            reselleredit:false
       
            };
        },
		created(){
            this.token = this.$store.getters.token
            this.id = this.$route.params.id
            this.user = this.$store.getters.user
            if(this.user.role < 20 || this.user.change_username == 1){this.adminedit = true}
            if(this.user.role == 5 || this.user.change_username == 1){this.reselleredit = true}
            if(this.user.role == 1 && this.user.business == 11){this.adminedit == true}
            this.emailto = this.user.email
            this.fetchRover()
		},

        mounted(){
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setprevroute',this.$route.name)
        },

         beforeDestroy () {
               clearInterval(this.timeout)
          },

      
        methods: {

			fetchRover(){

				axios.get('/api/companyroverstest',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.id,
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                            if(response.data.rovers[0] == undefined){
                                this.notauth = true
                            } else {

                            this.notauth = false
                            this.rover = response.data.rovers[0]
                           
                            if(this.rover.machine.length != 0){
                                this.machine = this.rover.machine[0]
                            } 
                            this.casta = response.data.casta[0]
                           
                            this.resellersel = this.rover.business
                            this.dealersel = this.rover.dealer
                            this.companysel = this.rover.company

                            if(this.rover.subscriptiondetail.length > 0){
                                this.sub = this.rover.subscriptiondetail[0]
                                this.sub.id = this.sub.value
                                this.sub.username = this.rover.username
                                this.sub.password = this.rover.password
                                if(this.sub.status == 'Expired'){
                                    this.sub.expirycolor = '#ff6060'
                                }
                                
                                if(this.sub.renewsub > 0 && this.sub.status == 'Scheduled'){
                                    this.sub.status = 'Expired'
                                }
                            }
                        
                            if(this.rover.renewsub.length != 0){
                                this.renewsub = this.rover.renewsub[0]
                            }  else this.renewsub = 0
                            this.roversubslength = this.rover.subscriptiondetail.length
                            this.id = this.rover.value

                            if(this.rover.simm.length != 0){
                            let consumed = Number(this.rover.simm[0].dataused);
                            this.consumed = consumed.toFixed(2)
                            this.simsupplier = this.rover.simm[0].supplier // 1 - Betterconnect 2 - BICS
                            }

                            if(this.rover.lastsession){this.lastsessiondate = this.rover.lastsession.created_at.substring(0, 10)}
                                else this.lastsessiondate = '-'
                            
                            this.display = true
                            this.update++

                            }
                    }
                    });
			},

           realTime(id){
               this.$router.push({ name: 'casterrover', params: {id:id} }).catch(()=>{})
           },

           history(id){
               this.$router.push({ name: 'casterroverhistory', params: {id:id} }).catch(()=>{})
           },

            roverAdd(){
                this.modaltitle = 'Edit Rover'
                this.$refs['rover-add'].show()
            },

            renewSub(){
                this.modaltitle = this.rover.text
                this.$refs['sub-renew'].show() 
            },

            cancelRenewSub(){
                this.modaltitle = this.rover.text
                this.$refs['renew-cancel'].show() 
            },

            cancelSub(){
                this.modaltitle = this.rover.text
                this.$refs['sub-cancel'].show() 
            },

            addSub(){
                this.modaltitle = this.rover.text
                this.$refs['sub-add'].show() 
            },

            showNote(note){
                this.notesel = note
                this.modaltitle = 'Rover Note'
                this.$refs['rover-note'].show() 
            },

            cancelNote(){
                this.$refs['rover-note'].hide() 
            },

            delNote(note){
                this.notesel = note
                this.modaltitle = 'Delete Note'
                this.$refs['rover-notedel'].show() 
            },

            deleteNote(){
                
                axios({
                    method: 'delete',  
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    url: '/api/casternote/'+this.notesel.id,
                    data: {
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.token,
                        }
                })

                .then((response)=> {

                    if(response.data.code == 999){
                            alert(response.data)
                            //this.errorresponse = response.data
                    } else {
                        this.$refs['rover-notedel'].hide()
                        this.fetchRover() 
                    }
            
                })
               
            },

            addNote(){
                this.notesel.id = 0
                this.notesel.user = this.$store.getters.user.id
                this.notesel.reseller = this.$store.getters.user.business
                this.notesel.dealer = this.$store.getters.user.dealer
                this.notesel.type = 1
                this.notesel.type_id = this.rover.value
                this.notesel.description = ''
                this.modaltitle = 'Rover Note'
                this.$refs['rover-note'].show() 
            },

            // editRover(){
            //    this.roversel = this.rover.value
            //    this.modaltitle = this.rover.title
            //    this.$refs['edit-rover'].show() 
            // },

            editMachine(){
                this.modaltitle = this.rover.title
                this.$refs['machine-edit'].show()
            },

       
           stopTimer(){
                clearInterval(this.timeout)
           },

           closeModal(){
                this.$refs['rover-add'].hide()
               // this.$refs['edit-rover'].hide()
                this.$refs['machine-edit'].hide()
                
           },

           updateModal(){
                this.$refs['rover-add'].hide()
                this.$refs['rover-notedel'].hide()
                this.$refs['rover-note'].hide()
                this.$refs['machine-edit'].hide()
                this.fetchRover()  
           },

            formatDate(timedate) {
                if(timedate == 0){return '-'}
                let date =  moment(timedate * 1000).format('DD MMM y HH:mm')   
                return date
            },

        
            handleHide2(){
                this.$refs['sub-renew'].hide() 
                this.$refs['sub-cancel'].hide() 
                this.$refs['renew-cancel'].hide()
                this.fetchRover()
            },

            copyText() {
                this.text = "GSM Username *leave blank\nGSM Password *leave blank\nGSM APN n/a\nRoaming Enabled\nNTRIP Address "+this.rover.connection+"\nNTRIP Port "+this.rover.port+"\nMount Point RTK_RTCM3\nNTRIP User "+this.rover.username+"\nNTRIP Password "+this.rover.password+"\n"
                navigator.clipboard.writeText(this.text)
            },

          
            back(){
                history.back()
            },
            }
	}
</script> 