<template>
  <div>
    <b-container class="mainpagecont" fluid>

      <b-row>
        <b-col class="col-12 col-sm-6 p-2">
          <h3>Network Overview</h3>
          <h4>{{ reseller.text }}</h4>
        </b-col>
        <b-col class="col-12 col-sm-6 p-2 text-left text-sm-right">
          <b-button size="sm" variant="outline-success" class="countrybutton"
            @click.stop="fetchReseller(0)">All</b-button>
          <b-button size="sm" variant="outline-success" class="countrybutton"
            @click.stop="fetchReseller(1, 1)">GBR</b-button>
          <b-button size="sm" variant="outline-success" class="countrybutton"
            @click.stop="fetchReseller(11, 2)">DNK</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mt-1 p-2 boxclickable" @click="menuLink('casterroverslogged')">
          <CasterRoversLoggedCard />
        </b-col>

        <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mt-1 p-2 boxclickable"
          @click.stop="menuLink('casterreferencestations')">
          <CasterBasestationsCard />
        </b-col>

        <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mt-1 p-2 boxclickable" @click="menuLink('castersubscriptions')">
          <CasterSubsCard />
        </b-col>

        <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mt-1 p-2">
          <CasterSimmUsageCardAdmin />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 col-md-6 mt-1 p-2">
          <CasterLastEventsCard />
        </b-col>

        <b-col class="col-12 col-md-6 mt-1 p-2">
          <CasterShowLeafletRovers :border="true" v-on:fullscreen="fullScreen" />
        </b-col>

        <b-col class="col-12 col-sm-6 mt-1 p-2">
          <CasterNetworkRTKChartCard />
        </b-col>

        <b-col class="col-12 col-sm-6 mt-1 p-2">
          <CasterNetworkLoginsChartCard />
        </b-col>

        <!-- <b-col class="col-12 col-sm-6 mt-1 p-2">
        <CasterStockingCard  />
    </b-col> -->
      </b-row>

      <b-row>
        <b-col class="col-12 mt-1 p-2">
          <h5>Action History</h5>
        </b-col>
        <b-col class="col-12 p-2">
          <CasterRoverEventsCard :update="true" />
        </b-col>
      </b-row>

    </b-container>

    <b-modal ref="full-screen" size="xl" title="ROVERS MAP" @show="waitSetWidth" @hide="closeMap" hide-footer centered
      content-class="darkmodal2">
      <div ref="fullscreendiv" :style="{ width: 100 + '%', height: 500 + 'px' }">
        <CasterShowLeafletRovers v-if="modalmapwidth != 0" :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth"
          :networkdisplay="1" />
      </div>
    </b-modal>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

  </div>
</template>

<script>
import CasterRoversLoggedCard from './CasterRoversLoggedCard'
import CasterBasestationsCard from './CasterBasestationsCard'
import CasterSubsCard from './CasterSubsCard'
import CasterSimmUsageCardAdmin from './CasterSimmUsageCardAdmin'
import CasterShowLeafletRovers from './CasterShowLeafletRovers'
import CasterLastEventsCard from './CasterLastEventsCard';
import CasterNetworkRTKChartCard from './CasterNetworkRTKChartCard'
import CasterNetworkLoginsChartCard from './CasterNetworkLoginsChartCard'
//import CasterStockingCard from './CasterStockingCard'
import CasterRoverEventsCard from './CasterRoverEventsCard'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
export default {
  name: 'AdminHome',
  components: {
    CasterRoversLoggedCard,
    CasterBasestationsCard,
    CasterSubsCard,
    CasterSimmUsageCardAdmin,
    CasterShowLeafletRovers,
    CasterLastEventsCard,
    CasterNetworkRTKChartCard,
    CasterNetworkLoginsChartCard,
    //CasterStockingCard,
    CasterRoverEventsCard,
    ErrorLogging
  },
  data() {
    return {
      token: '',
      reseller: {
        id: 0,
        title: '-',
        country: 0
      },
      resellerid: 0,
      showmap: false,
      modalmapwidth: 0,
      modalmapheight: 560,
      errorresponse: {},
      componentKey: 0
    };

  },

  created() {
    this.token = this.$store.getters.token
    if (this.$store.getters.roleid == 1 || this.$store.getters.roleid == 5) {
      this.$store.commit('setdealerid', 0)
      this.$store.commit('setcompanyid', 0)
    }
    this.fetchReseller(0)
  },

  mounted() {
    this.$store.commit('setbasesdatapoll', 1)
    this.$store.commit('setroversdatapoll', 1)
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setModalWidth)
  },

  methods: {

    fetchReseller(resellerid, country = 0) {
      axios.get('/api/casterbusinesses', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.token,
          id: resellerid,
          country: country,
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.reseller = response.data.CasterBusiness[0]
            if (resellerid == 0) {
              this.reseller.text = 'All'
              this.reseller.country = 0
            }

            switch (country) {
              case 1:
                this.reseller.text = 'GBR'
                this.reseller.country = country
                break;
              case 2:
                this.reseller.text = 'DNK'
                this.reseller.country = country
                break;
              default:
            }
            this.$store.commit('setresellerid', resellerid)
            this.$store.commit('setcountryid', this.reseller.country)
            this.$store.commit('setroversdata', '')
          }
        });
    },

    menuLink(name) {
      this.$router.push({ name: name, params: {} }).catch(() => { })
    },

    showMap() {
      this.showmap = true
    },

    fullScreen() {
      this.modalmapwidth = 0
      this.$refs['full-screen'].show()
    },

    waitSetWidth() {
      setTimeout(this.setModalWidth, 150)
    },

    setModalWidth() {
      window.addEventListener("resize", this.setModalWidth)
      this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth
    },

    closeMap() {
      window.removeEventListener("resize", this.setModalWidth)
      this.$refs['full-screen'].hide()
    }

  }

};

</script>