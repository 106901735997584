<template>
  <div>
    <b-container>

      <b-row>
        <b-col class="col-12 col-sm-6">
          <h3>Session History<br /><span style="font-size:0.7em;color:rgba(255,255,255,0.7)"> {{ rover.text }}</span>
          </h3>
        </b-col>
        <b-col class="col-6 text-right">
          <b-button size="sm" variant="outline-success" style="min-width:150px;margin-left:5px"
            v-if="rover.connected == 1" @click="showlive()">
            <b-icon-clock-history class="dicon" />VIEW LIVE
          </b-button>
          <b-button size="sm" variant="outline-success" style="min-width:150px;margin-left:5px" @click="manageRover()">
            <b-icon-card-list class="dicon" />MANAGE ROVER
          </b-button>
          <!-- <b-button size="sm" variant="outline-success" style="min-width:100px;margin-left:5px" @click="back()">
            <b-icon-arrow-left class="bicon" /> BACK
        </b-button> -->
        </b-col>
      </b-row>


      <b-row class="pb-5">

        <b-col class="col-12 col-md-6 mt-1 p-2">
          <CasterRoverLastEventsCard :logs="roverdata.logs" v-if="showdata" />
        </b-col>

        <b-col class="col-12 col-md-6 mt-1 p-2">
          <CasterShowLeafletRoverSession :mapdata="roverdata" :border="true" :sessionid="sessionid"
            :country="rover?.machine?.[0]?.country" v-on:fullscreen="fullScreen" />
        </b-col>

        <b-col class="col-12 mt-1 p-2">
          <CasterRoverHistorySession :session="session" :live="this.live" />
        </b-col>

        <b-col class="col-12 mt-1 p-2">
          <CasterRoverHistorySessionsTable :sessionid="sessionid" :id="id" v-on:session="setSession"
            v-if="sessionid > 0" />
        </b-col>

      </b-row>

    </b-container>

    <b-modal ref="full-screen" size="xl" title="SESSION TRACK" @show="waitSetWidth" @hide="closeMap" hide-footer
      centered content-class="darkmodal2">
      <div ref="fullscreendiv" :style="{ width: 100 + '%', height: 500 + 'px' }">
        <CasterShowLeafletRoverSession v-if="modalmapwidth != 0" :mapdata="roverdata" :fsmapheight="modalmapheight"
          :fsmapwidth="modalmapwidth" :border="false" :sessionid="sessionid" :country="rover?.machine?.[0]?.country" />
      </div>
    </b-modal>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
  </div>
</template>

<script>
import CasterRoverLastEventsCard from './CasterRoverLastEventsCard'
import CasterShowLeafletRoverSession from './CasterShowLeafletRoverSession'
import CasterRoverHistorySession from './CasterRoverHistorySession'
import CasterRoverHistorySessionsTable from './CasterRoverHistorySessionsTable'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
export default {
  name: 'CasterRoverHistory',
  components: {
    CasterRoverLastEventsCard,
    CasterShowLeafletRoverSession,
    CasterRoverHistorySession,
    CasterRoverHistorySessionsTable,
    ErrorLogging
  },
  data() {
    return {
      id: 0,
      roverdata: {
        logs: [],
        markers: [],
        points: [],
        session: []
      },
      showdata: false,
      mapshow: false,
      modalmapwidth: 0,
      modalmapheight: 485,
      session: {},
      sessionid: 0,
      errorresponse: {},
      rover: {},
      live: false,
      token: ''
    };

  },

  mounted() {
    this.token = this.$store.getters.user.token
    this.id = this.$route.params.id // Rover ID
    this.$store.commit('setbasesdatapoll', 0)
    this.fetchRover()
  },

  watch: {
    sessionid: function () {
      if (this.sessionid > 0) {
        this.getRoverSession();

      } else this.nodata = true
    },
  },

  methods: {

    fetchRover() {
      axios.get('/api/indexrovers', {
        headers: { "Authorization": "Bearer " + this.$store.getters.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.id
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.rover = response.data.rovers[0]
            this.sessionid = this.rover.lastsession
            if (this.rover.lastsession == this.rover.session) { this.live = true } else this.live = false
            this.getRoverSession()
          }

        })

    },


    getRoverSession() {
      axios.get('/api/castaroversessionnew', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.token,
          rover: this.id,
          session: this.sessionid,
          sessiondata: 1,
          ggaTest: 1
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.roverdata = response.data
            this.showdata = true
          }
        });
    },

    setSession(event) {

      if (event !== undefined) {
        this.session = event
        this.sessionid = this.session.session_id
        if (this.rover.lastsession == this.sessionid) { this.live = true } else this.live = false
      }
    },

    showlive() {
      this.$router.push({ name: 'casterrover', params: { id: this.rover.value } }).catch(() => { })
    },

    manageRover() {
      this.$router.push({ name: 'casterrovermanage', params: {} }).catch(() => { })
    },

    fullScreen() {
      this.modalmapwidth = 0
      this.$refs['full-screen'].show()
    },

    waitSetWidth() {
      setTimeout(this.setModalWidth, 150)
    },

    setModalWidth() {
      this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth - 20
    },

    closeMap() {
      this.$refs['full-screen'].hide()
    },

    back() {
      history.back()
    },

  }

};

</script>