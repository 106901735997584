<template>
    <div ref="mainDiv" class="mainDiv-cs">

        <!--Header/Fullscreen-->
        <b-row>
            <b-col class="col-9 mt-3 textfade"><h6>SKY PLOT</h6></b-col>
            <b-col class="col-3 mt-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand/Contract" />
            </b-col>  
        </b-row>

        <!--Loading/Change Sat Icon-->
        <b-row>
            <b-col class="col-12">
                <h4 ref="loadingH" v-if="loading">
                    LOADING
                    <b-icon icon="three-dots" animation="cylon" />
                </h4>
            <div ref="uiBtns" class="uiButtons text-right " v-show="!loading && satTotal > 0">
                    <b-icon icon="arrow-clockwise" v-b-tooltip.hover title="Cycle Icons" @click.stop="cycleIcon"/>
            </div>
                <div ref="cnvsWrpr" class="canvasWrapper " v-show="!loading && satTotal > 0">
                    <canvas ref="satCnvs" width="100" height="100"/>
                </div>
            </b-col>
        </b-row>

        <!--Constellation Buttons-->
        <b-row class="mb-1" v-show="satTotal > 0">
            <b-col ref="satBtns" class="col-12 " v-show="!loading">
                <b-button-group ref="buttonGroup" class="buttonGrid">
                    <b-button ref="gpsBtn" id="gps" class="satButton"  size="sm" variant="outline-success" @click.stop="constelVisibility('gps')">GPS: ?</b-button>
                    <b-button ref="glonassBtn" id="glonass" class="satButton" size="sm" variant="outline-success" @click.stop="constelVisibility('glonass')">GLONASS: ? </b-button>
                    <b-button ref="beidoBtn" id="beido" class="satButton" size="sm" variant="outline-success" @click.stop="constelVisibility('beido')">BEIDOU: ? </b-button>
                    <b-button ref="galileoBtn" id="galileo" class="satButton" size="sm" variant="outline-success" @click.stop="constelVisibility('galileo')">GALILEO: ? </b-button>
                    <b-button ref="qzssBtn" id="qzss"  class="satButton" size="sm" variant="outline-success" @click.stop="constelVisibility('qzss')">QZSS: ? </b-button>
                </b-button-group>
            </b-col>
        </b-row>

        <b-row class="mb-1" v-show="satTotal == 0">
            <b-col class="col-12 text-center">No Satellite Data</b-col>
        </b-row>    
        
    </div>
</template>

<style scoped>
.mainDiv-cs{
    height: auto;
}
.canvasWrapper{
    width: 100%;
    max-height: 100vh;
}
.buttonGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
}
.satButton{
    padding-top:5px;
    padding-bottom:5px;
    min-width:18%;
    margin-right:5px;
}
.satButton:focus{
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 40, 40, 0.7);
}
.uiButtons{
    position: absolute;
    right: 15px;
    
}
.hidden{
    visibility: hidden;
}


</style>

<script>
import axios from 'axios';

export default {
    props: { 
        icon: {
            type: String,
            required: false,
            default: "satellite",
        },
        border: {
            type: Boolean,
            required: false,
            default: false
        },
        base: {
            type: Object,
            required: true,
        },
    },
    name:'CasterReferenceStationSkyPlot',
    data(){
        return{
            token:'',
            cnvs:null,
            ctx:null,
            centerX:0,
            centerY:0,
            maxRadius:0,
            angleDegOffset: -90,//to start 0 deg from the "12 oclock" pos
            frameInterval:null,
            fps:1, //0-30, 30 < will use requestAnimationFrame = 60fps. and for effecient
            plots:[],
			satStore:{
                gps:        { apiLetter: "P", visible: false, sats:[], color:"rgba(60,210,165,0.7)", type:"gps" },
                glonass:    { apiLetter: "L", visible: false, sats:[], color:"rgba(249,232,13,0.7)", type:"glonass" },
                beido:      { apiLetter: "B", visible: false, sats:[], color:"rgba(255,96,96,0.7)", type:"beido"  },
                galileo:    { apiLetter: "A", visible: false, sats:[], color:"rgba(222,140,255,0.7)", type:"galileo"  },
                qzss:       { apiLetter: "Q", visible: false, sats:[], color:"rgba(187,164,255,0.7)", type:"qzss"  },
            },
            satSVGs:{
                gps:    { dataUrl:"", imageElement:null },    
                glonass:{ dataUrl:"", imageElement:null },
                beido:  { dataUrl:"", imageElement:null },
                galileo:{ dataUrl:"", imageElement:null },
                qzss:   { dataUrl:"", imageElement:null },
            },
            satTotal: 0,
            loading:true,
            satIcon:"",
            frameErrorLimit:30,
            frameErrorCount:0,
            clearCacheInterval:null,
            fetchAllSatsInterval:null,


        }
    },
    created(){
        //set variables
        this.token = this.$store.getters.token 
        this.satIcon = this.icon;

        //set SessionStorage Cache
        this.createSkyplotCache();
    },
    mounted(){
        //setup stage 1
        this.addBorder(this.border);

        //check base prop exists and has properties
        if(!this.checkBasePropIsValid())return;

        //setup stage 2
        this.addBaseToSkyplotCache();
        this.setupCanvas();
        this.createSatSVGs();
        this.setButtonsColors();
        
        this.constelVisibility('gps');
        this.constelVisibility('glonass');
        this.constelVisibility('beido');
        this.constelVisibility('galileo');
        this.constelVisibility('qzss');

        const twoAndAHalfMins = 1000 * 150;
        this.fetchAllSatsInterval = setInterval(this.fetchAllSats, twoAndAHalfMins);

        this.setupCanvas();
        this.setButtonsColors();

        this.initFrameLoop();
    },
    beforeDestroy(){
        //clear intervals/events/animation
        window.removeEventListener("resize", this.newCanvasFrame);
        clearInterval(this.clearCacheInterval);
        clearInterval(this.fetchAllSatsInterval);

        if(this.fps <= 0){console.error(`sat plot fps needs to be over 0`);return;}
        if(this.fps > 30 && this.fps <= 60){
            window.cancelAnimationFrame(this.frameInterval);
        }else{
            clearInterval(this.frameInterval);
        }
    },
    computed:{
        sideMenuState(){
            return this.$store.getters.openmenu;
        },
        satStoreState(){
            return this.satStore;
        },
    },
    watch:{
        sideMenuState(){
            //need to redraw when the side menu expands/contracts
            this.newCanvasFrame();
        },
        satStoreState:{
            //looking at "visible" inside satStore
            handler(){
                this.setButtonsColors();
                this.emitSatStore();
            },
            deep:true
        },
    },
    methods:{
        //checks
        checkBasePropIsValid(){
            if(!this.base || typeof this.base !== "object" || !("type" in this.base)){
                console.error(`CasterRefrenceStationSkyPlot: base not found:\n`,this.base,`\n should be refstation passed from CasterRefrenceStation`);
                this.$refs.loadingH.innerText = "Base not given correctly.";
                return false;
            }
            return true;
        },
        addBorder(boole){
            //in a function because the fullscreen comp has its own border
            if(boole){
                this.$refs.mainDiv.classList.add("imageBorder");
            }
        },

        //cache
        createSkyplotCache(){
            if(sessionStorage.getItem('skyplotBaseData')) return;
            sessionStorage.setItem('skyplotBaseData', JSON.stringify([])); 
        },
        addBaseToSkyplotCache(){
            const cacheArray = JSON.parse(sessionStorage.getItem("skyplotBaseData"));
            const tenMins = 1000 * 60 * 10

            //--check if base exists and if it has timed out--------------------------------------// 
            const baseObj = cacheArray.find( (baseObj) => {
                return baseObj.baseId === this.base.value;
            });

            const baseTimeout = () => {
                const today = new Date()

                if(!baseObj)return false;
                if(baseObj.lastUpdateDate !== today.toDateString()) {
                    return true;
                    }
                if(today.getTime() - baseObj.lastUpdateTime < tenMins) {
                    return false;
                    }
                return true;

            }

            if(baseObj && !baseTimeout()){return;}
            //------------------------------------------------------------------------------------//

            //add base / clearupdate base to be empty ready for new sat data.
            const timestamp = new Date();

            const newBase = { 
                baseId: this.base.value,
                lastUpdateDate: timestamp.toDateString(),
                lastUpdateTime: timestamp.getTime(),
                gpsSats: [],
                glonassSats: [], 
                beidoSats: [],
                galileoSats: [],
                qzssSats: []  
            }

            //If base exists replace, otherwise add to array
            if(baseObj){
                const baseObjIndex = cacheArray.indexOf(baseObj);
                cacheArray[baseObjIndex] = newBase;
            }else{
                cacheArray.push(newBase); 
            }
            
            //save to cache
            const newBaseJson = JSON.stringify(cacheArray);
            sessionStorage.setItem('skyplotBaseData', newBaseJson);

            //reapeat after 10mins
            if(this.clearCacheInterval)return;
            this.clearCacheInterval = setInterval( this.addBaseToSkyplotCache, tenMins);
        },
        addSatsToSkyplotCache(constelName){
            const cacheArray = JSON.parse(sessionStorage.getItem("skyplotBaseData"));

            //grab base from cache vith id/value
            const baseObj = cacheArray.find( (baseObj) => {
                return baseObj.baseId === this.base.value;
            });

            const baseObjIndex = cacheArray.indexOf(baseObj);

            //grab sats from data satStore, add to baseObject for cache
            let storeSats = this.satStore[constelName].sats;
            baseObj[`${constelName}Sats`] = storeSats;
            
            //update base in cache
            cacheArray[baseObjIndex] = baseObj;
            const updatedCacheArrayString = JSON.stringify(cacheArray);
            sessionStorage.setItem('skyplotBaseData', updatedCacheArrayString);
        },

        //canvas
        drawBackground(){
            this.ctx.fillStyle = "rgba(55,55,55,0)";
            this.ctx.fillRect(0, 0, this.cnvs.width, this.cnvs.height);
        },
        drawRadar(){  
            
            const maxWidthOfRadar = (this.cnvs.width * 0.8);
            this.maxRadius = maxWidthOfRadar / 2; 
            const angleStepDeg = 15;
            const numberOfRings = 3;
            this.ctx.fillStyle = "rgba(173, 173, 173, 0.6)";
            this.ctx.strokeStyle = "rgba(173, 173, 173, 0.60)";

            //Draw rings, equally spaced
            for(let n = 1; n <= numberOfRings; n++){
                const radius = n * (this.maxRadius / numberOfRings);

                this.ctx.beginPath();
                this.ctx.arc(this.centerX, this.centerY, radius, 0, (2 * Math.PI));
                this.ctx.stroke();
            }

            //Draw angle lines and degres text
            let degres = angleStepDeg;

            while(degres <= 360){
            
                if(degres % 90 === 0){//for North South East West
                    this.ctx.strokeStyle = "rgb(255, 255, 255)";
                }else{
                    this.ctx.strokeStyle = "rgba(173, 173, 173, 0.60)";
                }

                const angleDeg = 90 - (degres + this.angleDegOffset);
                const angleRad =  angleDeg * (Math.PI / 180) ;
                const widthDisplacment = Math.sin(angleRad) * this.maxRadius;
                const heightDisplacment = Math.cos(angleRad) * this.maxRadius;
                const endXPos = this.centerX + widthDisplacment;
                const endYPos = this.centerY + heightDisplacment;

                const text = () => {
                    if(degres === 360){
                        return `0°`;
                    }
                    return `${degres}°`;
                }
                //text pos needs to slightly more displaced than the outer ring
                const textXPos = this.centerX + (widthDisplacment * 1.1);
                const textYPos = this.centerY + (heightDisplacment * 1.1);
                
                //draw lines
                this.ctx.beginPath();
                this.ctx.moveTo(this.centerX, this.centerY);
                this.ctx.lineTo(endXPos, endYPos);
                this.ctx.stroke();

                //draw text
                this.ctx.font = `${Math.round(0.02 * this.cnvs.width)}px michroma`;
                this.ctx.fillStyle = "rgba(255,255,255,0.7";
                this.ctx.textBaseline = "middle"; 
                this.ctx.textAlign = "center"; 
                this.ctx.fillText(text(), textXPos, textYPos)

                degres += angleStepDeg;
            }
        },
        createSatSVGs(){
            const satIconWidth = 0.02 * this.cnvs.width;
            const satIconHeight = 0.02 * this.cnvs.width;

            Object.keys(this.satSVGs).forEach( (key) => {
                this.satSVGs[key].imageElement = new Image(satIconWidth, satIconHeight);

                const iconColor = this.satStore[key].color
                const iconSvgString = `<svg width="1e3" height="1e3" version="1.1" viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(1.675 -.43592)">
                <path d="m168.47 247.21 6.4592-10.519 56.408-53.535 10.72-5.9302z" fill="${iconColor}" stroke="${iconColor}" stroke-linecap="square" stroke-width=".17313" style="paint-order:normal"/>
                <path d="m171.46 199.47 0.45364-5.3251 18.911-17.922 5.2496-0.24093z" fill="${iconColor}" stroke="${iconColor}" stroke-linecap="square" stroke-width=".10587" style="paint-order:normal"/>
                <path d="m172.45 228.05 3.6938-8.1586 39.244-37.233 8.2319-3.3092z" fill="${iconColor}" stroke="${iconColor}" stroke-linecap="square" stroke-width=".14871" style="paint-order:normal"/>
                <path d="m172.3 212.99 2.4688-6.1455 28.486-27.024 6.181-2.1852z" fill="${iconColor}" stroke="${iconColor}" stroke-linecap="square" stroke-width=".11454" style="paint-order:normal"/>
                <path d="m198.75 134.74c-73.079-4.5498-64.403 32.518-67.313 63.688 18.381-22.717 44.042-46.375 67.313-63.688z" fill="#515151" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" style="paint-order:normal"/>
                <g fill="${iconColor}" stroke="#000" stroke-width="2.5">
                <path d="m57.351 52.335-5.8382 40.44 27.719 29.189-6.5541 6.2241-12.451-13.111-60.134 57.106 32.688 34.422 60.134-57.106-13.852-14.586 6.5541-6.2241 42.607 44.866 45.769-43.464-42.607-44.866 6.1222-5.8139 13.599 14.32 60.134-57.106-32.688-34.422-60.134 57.106 12.704 13.377-6.1222 5.8139-27.719-29.189z"/>
                <path d="m198.61 134.77c-73.079-4.5498-64.21 32.061-67.119 63.231 9.7345-28.866 25.208-54.14 67.119-63.231z" stroke-linecap="round" stroke-linejoin="round" style="paint-order:normal"/>
                </g>
                <path d="m155.52 157.01 22.761 23.84" fill="#515151" stroke="#181818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" style="paint-order:normal"/>
                <path d="m183.15 139.36-4.45 41.966" fill="#515151" stroke="#181818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" style="paint-order:normal"/>
                <path d="m138.2 182.43 40.43-1.1538" fill="#515151" stroke="#181818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" style="paint-order:normal"/>
                </g>
                </svg>
                `
                const encodedSvgString = encodeURIComponent(iconSvgString)
                const dataUrl = "data:image/svg+xml," + encodedSvgString;

                this.satSVGs[key].imageElement.src = dataUrl;
            })      
        },
        drawSatellites(plots, iconColor, constell, icon = this.satIcon){
          if (!this.satTotal > 0) return;
            //the satellites are split into constellations, we want to have them display individually

            //-- prop checks ---------------------------------------------------------------------//

            //prevent icon prop case sensivity
            if(typeof icon === "string" ){icon = icon.toLowerCase();}

            //limit error messages count, as this function will be called with each frame
            if(this.frameErrorCount <= this.frameErrorLimit){               
                //stop if no plots and error
                if(!plots || plots.length === 0){
                    console.error(`CasterReferencestationSatplot: drawSatellites(): No plots to display`); 
                    this.frameErrorCount++; 
                    return;
                    }
                
                //inform icon prop is invalid, continue using default
                if(icon != "triangle" && icon != "satellite" && icon != "circle" && icon != undefined){
                    console.error(`CasterReferencestationSatplot: drawSatellites(): 
                    icon:${icon}, is not an option, please use "circle", "satellite" or "triangle"`); 
                    this.frameErrorCount++; 
                }
            }
            //just stop if no plots
            if(!plots || plots.length === 0) return; 

            //------------------------------------------------------------------------------------//

            //go through each satellites data and draw it onto our skyplot
            plots.forEach(plot => {

                /*Elevation is 0-90. Elevation is angle from tangent of earth to sat, so 0 degrees means on the horizon and 90 degrees means above you.
                Therefor, 90 means centre of radar, 0 means edge. We need to convert that scale to a max radius percnetage with 0 = 100% and 90 = 0%*/
                const anglePercentage = plot.Elevation / 90;
                const radiusLinearPercentage = 1 - anglePercentage;
                const radius = this.maxRadius * radiusLinearPercentage;
                const angleDeg =  90 - (plot.Azimuth + this.angleDegOffset);
                const angleRad =  angleDeg * (Math.PI / 180) ;
                const widthDisplacment = Math.sin(angleRad) * radius;
                const heightDisplacment = Math.cos(angleRad) * radius;
                const xPos = this.centerX + widthDisplacment;
                const yPos = this.centerY + heightDisplacment;

                //set up text, is drawn based on icon bellow
                this.ctx.textAlign = "left"; 
                this.ctx.textBaseline = "middle";
                this.ctx.font = `bold ${Math.round(0.02 * this.cnvs.width)}px michroma`;
                

                //draw icon based on icon prop
                switch (icon){
                    case "satellite":{
                        const satIconWidth = 0.02 * this.cnvs.width;
                        const satIconHeight = 0.02 * this.cnvs.width;
                        const satelliteIcon = this.satSVGs[constell.type].imageElement;

                        this.ctx.drawImage(satelliteIcon, (xPos - satIconWidth), (yPos - satIconHeight), (0.05 * this.cnvs.width), (0.05 * this.cnvs.width) );
                        
                        this.ctx.fillStyle = "rgba(255,255,255,0.7";
                        this.ctx.fillText(`  ${plot.PRN}`, (xPos + 0.015 * this.cnvs.width), yPos)
                        break;
                    }
                    case "triangle":
                        this.ctx.fillStyle = iconColor;
                        this.ctx.beginPath();
                        this.ctx.moveTo(xPos, (yPos - 0.015 * this.cnvs.width));
                        this.ctx.lineTo((xPos - 0.02 * this.cnvs.width), (yPos + 0.01 * this.cnvs.width));
                        this.ctx.lineTo((xPos + 0.02 * this.cnvs.width), (yPos + 0.01 * this.cnvs.width));
                        this.ctx.lineTo(xPos, (yPos - 0.015 * this.cnvs.width));
                        this.ctx.closePath()
                        this.ctx.fill();
                        
                        this.ctx.fillStyle = "rgba(255,255,255,0.7";
                        this.ctx.fillText(`  ${plot.PRN}`, (xPos + 0.01 * this.cnvs.width), yPos)
                        break;
                    default://"circle"
                        this.ctx.fillStyle = iconColor;
                        this.ctx.beginPath();
                        this.ctx.arc(xPos, yPos, (0.01 * this.cnvs.width), 0, 2 * Math.PI);
                        this.ctx.fill();

                        this.ctx.fillStyle = "rgba(255,255,255,0.7";
                        this.ctx.fillText(`  ${plot.PRN}`, (xPos ), yPos)
                }
            });
        },
        drawConstellations(){
            //draw all sats for visible constellations
            Object.values(this.satStore).forEach( (constell) => {
                if(!constell.visible || !constell.sats.length > 0) return;
                this.drawSatellites(constell.sats, constell.color ,constell);
            });
        },
        setupCanvas(){
            //Variables
            this.cnvs = this.$refs.satCnvs;
            this.ctx = this.cnvs.getContext("2d");
            this.cnvs.width = this.$refs.cnvsWrpr.clientWidth
            this.cnvs.height = this.$refs.cnvsWrpr.clientHeight
            this.centerX = this.cnvs.width / 2;
            this.centerY = this.cnvs.height / 2;

            //Drawing
            this.drawBackground();
            this.drawRadar();
        },

        updateSatTotal() {
            if( !Object.keys(this.satStore).length > 0) return;
            this.satTotal = 0;
            Object.values(this.satStore).forEach( (constel) => {
                this.satTotal += constel.sats.length;
            })
        },

        newCanvasFrame(){
            if (!this.satTotal > 0) return;

            //update dimensions
            this.cnvs.width = this.$refs.cnvsWrpr.clientWidth
            this.cnvs.height = this.cnvs.width;
            this.centerX = this.cnvs.width / 2;
            this.centerY = this.cnvs.height / 2;
            this.divHeight();


            //clear & draw new frame
            this.ctx.clearRect(0,0,this.cnvs.width,this.cnvs.height);
            this.drawBackground();
            this.drawRadar();
            this.drawConstellations();
        },
        sixtyOrLessFpsRequestAnimationFrame(){
            //used in initFrameLoop
            this.newCanvasFrame()
            this.frameInterval = window.requestAnimationFrame(this.sixtyOrLessFpsRequestAnimationFrame);
        },
        initFrameLoop(){
            //depending on fps create frame loop using requestAnimationFrame or Interval.
            if(this.fps <= 0){
                console.error(`sat plot fps needs to be over 0`)
                return;
            }
            if(this.fps > 30){
                this.frameInterval = window.requestAnimationFrame(this.sixtyOrLessFpsRequestAnimationFrame);
            }else{
                let interval = 1000 / this.fps
                this.frameInterval = setInterval(this.newCanvasFrame, interval)
            }

            window.addEventListener("resize", this.newCanvasFrame);
        },

        //fetch data api/cache
        getConstLetter(constName){
            //slightly legacy as with current struction i could just do satStore.constName.apiLetter
            //however this includes and extra safty check, may as well leave it.
            let constLetter = "";
            Object.entries(this.satStore).forEach( ([key, value]) => {
                if(key === constName){
                    constLetter = value.apiLetter;
                }
            });
            if(constLetter === "") {
                console.error(`CasterRefernceStationSkyPlot: getConstLetter(), letter not found for: "${constName}"`);
                return
            ;}
            return constLetter;
        },
        fetchSatsByConstel(constName){
            //returns a promise with sat data, either from cache or api
            const constelLetter = this.getConstLetter(constName);
            if(!constelLetter)return;

            //-- first try to get sat data from cache  -------------------------------------------//
            const cacheArray = JSON.parse(sessionStorage.getItem("skyplotBaseData"));
            const baseObj = cacheArray.find( (baseObj) => {
                return baseObj.baseId === this.base.value;
            });
            
            
            if(baseObj[`${constName}Sats`].length > 0){
                return new Promise ( (resolve) => {                       
                    //use cache data to update satStore
                    this.satStore[constName].sats = baseObj[`${constName}Sats`];
                    this.updateSatTotal();
                    this.loading = false; 
                    resolve();
                })
            } 
            //------------------------------------------------------------------------------------//
            
            //get sat data from api
            return new Promise ( (resolve, reject) => {

                axios.get('/api/getsatplotstest',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.base.value,
                        type:this.base.type,
                        const:constelLetter,
                    }
                })
                .then((response) => {

                    if(response.status !== 200){
                        console.error(`CasterRefrenceStationSkyPlot: FetchSatsByConstel: error status:${response.status}`);
                        this.$refs.loadingH.innerText = "API failure, request not successful";
                        reject()
                        return;
                    }
                    if(response.data.code === 999){
                        console.error(`CasterRefrenceStationSkyPlot: FetchSatsByConstel: error 999`);
                        console.error(response.data.messages);
                        this.$refs.loadingH.innerText = "API failure, error 999.";
                        reject()
                        return;
                    }
                    if(response.data.error){                      
                        console.error(`CasterRefrenceStationSkyPlot: FetchSatsByConstel: error true`)
                        console.error(response.data.messages);
                        this.$refs.loadingH.innerText = `API failure, ${response.data.messages}`;
                        reject(response.data.messages)
                        return;
                    }
                    if(response.data === 'NOT AUTHORISED'){                      
                        console.error(`CasterRefrenceStationSkyPlot: FetchSatsByConstel: Not Authorised`)
                        this.$refs.loadingH.innerText = "API failure, Not Authorised";
                        reject()
                        return;
                    }  
                    

                
                    //put relevent sat data within satStore
                    if(Array.isArray(response.data.data) && response.data.data.length > 0){
                        this.satStore[constName].sats = [];
                        this.satStore[constName].sats.push(...response.data.data);
                        this.addSatsToSkyplotCache(constName);
                    }
                    this.updateSatTotal();
                    this.loading = false;   
                    resolve();         
                })
                .catch(err => {

                    console.error(`CasterRefrenceStationSkyPlot: FetchSatsByConstel:`)
                    console.error(err);
                    reject()
                });
            });
        },
        fetchAllSats(){
            this.fetchSatsByConstel('gps');
            this.fetchSatsByConstel('glonass');
            this.fetchSatsByConstel('beido');
            this.fetchSatsByConstel('galileo');
            this.fetchSatsByConstel('qzss');
        },
        emitSatStore(){
            this.$emit('satStore', this.satStore,this.satTotal);
        },

        //User interaction
        constelVisibility(constelName){
            this.addBaseToSkyplotCache();
            this.fetchSatsByConstel(constelName)
                .then( () => {
                    //change button text to show num of sats
                    if(!this.$refs[constelName+"Btn"])return;
                    this.$refs[constelName+"Btn"].innerText = `${this.$refs[constelName+"Btn"].id.toUpperCase()}: ${ this.satStore[constelName].sats.length }`
                })
                .catch( err => console.error(err) );   

            //flip visibile flag and draw a frame with new constel
            this.satStore[constelName].visible = !this.satStore[constelName].visible;
            this.newCanvasFrame();
        },
        fullScreen(){
            this.$emit('skyplotFullscreen')
        },
        divHeight(){
            this.$emit("height", {
                fullHeight:this.$refs.mainDiv.offsetHeight, 
                contentHeight: (this.$refs.satCnvs.clientHeight + this.$refs.buttonGroup.clientHeight) 
            });
        },
        setButtonsColors(){
            //remove unwanted css, and add custom colour based on constel visibility, (watch fn)
            this.$refs.satBtns.querySelectorAll("button").forEach( (btn) => {
                btn.classList.remove("btn-outline-success");
                Object.entries(this.satStore).forEach( ([key, value]) => {
                    if(btn.id !== key)return;
                    if (value.visible){
                        btn.style.color = "rgba(0,0,0,0.7)";
                        btn.style.borderColor = "rgba(0,0,0,0.7)";
                        btn.style.backgroundColor = value.color;
                    }else{
                        btn.style.color = value.color;
                        btn.style.borderColor = value.color;
                        btn.style.backgroundColor = "rgba(0,0,0,0)";
                    }
                })
            });            
        },
        cycleIcon(){
            //cycle satellite icon and update frame
            const iconList = ["satellite","triangle","circle"];
            
            //if icon prop is invalid use default circle  
            let pos = iconList.indexOf(this.satIcon);
            if(pos === -1){console.error(`CasterReferenceStationSkyPlot: cycleIcon, icon: ${this.satIcon} is invalid`); pos = 2;}
            if( (pos + 1) > 2){
                pos = 0; 
            }
            else pos++

            this.satIcon = iconList[pos]
            this.newCanvasFrame();
        }, 
    }
}
</script>

