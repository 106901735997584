<template>
<div>

    <b-modal ref="system-error"  hide-header hide-footer centered content-class="errormodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                    <h3>{{ message1 }}</h3>
            </b-col>
            <b-col class="col-12 mt-3 text-center">
                    <h5>{{ message2 }}</h5>
            </b-col>
        </b-row> 
    </b-modal>  

</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        name: 'ErrorLogging',
        props:['errorresponse','component'],
        data(){
            return {
                message1:'',
                message2:''
            }
        },
        watch: {
            errorresponse: function () {
                this.logError(this.errorresponse)
            },
        },
       
		methods: {

            logError(error){

                axios({
                    method: 'post',   
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    url: '/api/logerror',
                    data: {
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        code:error.type,
                        component:this.component,
                        sentrequest:error.request,
                        response:error.message,
                        post:error.post,
                        file:error.file,
                        line:error.line,
                        country:this.$store.getters.countryid,
                        reseller:this.$store.getters.resellerid,
                        dealer:this.$store.getters.dealerid
                    }
                })
                .then((response)=> {
                    
                    if(response.data.code == 200){
                        this.message1 = "System Error"
                        this.message2 = 'Support code : \n'+response.data.ref
                    } else {
                        this.message1 = `Logging Error, response: ${JSON.stringify(response)}`
                    }
                    if(this.$refs['system-error']){
                        this.$refs['system-error'].show()        
                    }
                });	
            },
		}	   
	
	};

</script>