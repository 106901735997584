<template>
<div>

<b-row class="mt-3">
     <b-col class="col-12 text-right">{{ logins.length }} results</b-col>

      <b-col class="col-12">
             <b-table 
                responsive 
                hover 
                :items="logins" 
                :fields="fields"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showRover($event)"
                :sort-by="sortBy"
                sort-icon-left
                dark
                >

              <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>    

             </b-table>       
     </b-col>
</b-row>	

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['dealer','start','end','period'],
        name:'CasterNetworkLoginsTable',
        components:{
            ErrorLogging
        },
        data(){
            return {
                id:0,
                token:'',
                logins:[],
                subsel:0,
                fields:[],
                sortBy:'date_time',
                modaltitle:'',
                display:false,
                isBusy:true,
                errorresponse:{}
                };
                
                },

		mounted(){
            this.token = this.$store.getters.token
            this.setFields()
            this.fetchLogins()
        },

         watch: {
                    start: function () {
                         this.fetchLogins()
                         },
        },          

		methods: {

            setFields(){
                //if(this.dealer == 0){
                this.fields = [
                { key: 'datetime', label: 'Date/time', formatter: 'formatDate', sortable: true  },
                { key: 'rover[0].text', label: 'Rover', sortable: true  },
                { key: 'rover[0].company', label: 'Company', sortable: true  },
                { key: 'rover[0].dealer', label: 'Dealer', sortable: true  },
                { key: 'useragent', label: 'User Agent', sortable: true  },
                { key: 'connectiontime', label: 'Consumed', sortable: true  },
                { key: 'bytessent', label: 'Transfered Data', sortable: true  },
                ]
            },

            fetchLogins(){
                this.isBusy = true
				axios.get('/api/networkloginsdata',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         start:this.start,
                         end:this.end,
                         period:this.period,
                         dealer:this.dealer
                     }
                })
				.then((response) => {

                    if(response.data.code == 999){
                            this.errorresponse = response.data.messages
                        } else {
                            this.logins = response.data.Logins
                            this.display = true
                            this.isBusy = false
                        }   
                    })
			},

            showRover(event){
                this.$router.push({ name: 'casterrovermanage', params: {id:event.rover[0].value} }).catch(()=>{})
            },

             formatDate(timedate) {
                let date =  moment(timedate).format('DD-MM-YY HH:mm') 
                return date
            }
       
		}	   
	
	};

</script> 


